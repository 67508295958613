import { useState, useEffect } from "react";
import "./App.css";
import * as tf from "@tensorflow/tfjs";
import ImageUpload from "./components/ImageUpload";

function App() {
  const [model, setModel] = useState(null);
  const [prediction, setPrediction] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const loadModel = async () => {
    try {
      // When loading this to Hostinger with a sub folder change the "/model.json" below to "/subfolder/model.json"
      const model = await tf.loadLayersModel("/model.json");
      setModel(model);
      console.log("Model loaded successfully");
    } catch (error) {
      console.error("Error loading model", error);
    }
  };

  const classifyImage = async (imageFile) => {
    if (!model) {
      alert("Model not loaded!");
      return;
    }
    setIsLoading(true);

    // Create an HTMLImageElement from the File object
    const imageElement = await loadImage(imageFile);

    const tensor = tf.tidy(() => {
      // Convert the image element to a tensor
      let image = tf.browser.fromPixels(imageElement);

      // Resize the image to the expected input size for the model
      image = tf.image.resizeBilinear(image, [150, 150]);

      // Normalize the image to the range [0, 1]
      image = image.div(255.0);

      // Add a batch dimension
      return image.expandDims(0);
    });

    try {
      const predictionTensor = await model.predict(tensor);
      const predictionValue = predictionTensor.dataSync()[0]; // Extract the value
      const label = predictionValue > 0.5 ? "Messy" : "Clean"; // Interpret the value
      setPrediction(`Prediction: ${label} (${predictionValue.toFixed(2)})`);

      setIsLoading(false);
    } catch (error) {
      console.error("Error during prediction", error);
    } finally {
      tensor.dispose();
    }
  };

  // Helper function to load an image file as an HTMLImageElement
  const loadImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    loadModel();
  }, []);

  return (
    <div className="App">
      <header className="app-header">
        <h1>Clean or Messy Room</h1>
      </header>
      <main className="main-content">
        <ImageUpload onUpload={classifyImage} />
        {prediction && (
          <div className="card prediction-card">
            <p>{prediction}</p>
          </div>
        )}
        {isLoading && (
          <div className="loader">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        )}
      </main>
    </div>
  );
}

export default App;
