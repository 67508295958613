import React, { useState } from "react";
import "./ImageUpload.css"; // Assume you create a separate CSS for this component

export default function ImageUpload({ onUpload }) {
  const [image, setImage] = useState(null);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImage(URL.createObjectURL(file));
      onUpload(file);
    }
  };

  return (
    <div className="image-upload-container">
      <label htmlFor="upload-button" className="upload-label">
        <span className="upload-button-text">Image upload</span>
        <input
          id="upload-button"
          className="upload-btn"
          type="file"
          onChange={handleImageChange}
          style={{ display: "none" }}
        />
      </label>
      {image && (
        <div className="image-preview-container">
          <img src={image} alt="Upload Preview" className="image-preview" />
        </div>
      )}
    </div>
  );
}
